<template>
  <div class="modal">
    <div class="modal__container">
      <div class="club-modal">
        <button class="club-modal__close" @click="close"></button>
        <div class="club-modal__title">{{ $t(title) }}</div>
        <div class="club-modal__descr">
          {{ $t(text) }}
        </div>
        <img :src="getImage()" alt="modal" class="club-modal__img" />
        <locale-router-link
          tag="button"
          :to="'/contacts'"
          v-if="isContactsAppeal"
          class="club-modal__button"
        >
          {{ $t(btn) }}
        </locale-router-link>
        <button v-else class="club-modal__button" @click="close">
          {{ $t(btn) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModalReceived",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    btn: {
      type: String,
      required: true,
    },
  },
  computed: {
    isContactsAppeal() {
      if (this.$route.name === "Appeal") {
        return true;
      }
      return false;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getImage() {
      return require("@/assets/img/icons/" + this.image);
    },
  },
};
</script>
